<template>

<div class="textbox" :class="{'is-error': error}">

	<app-icon s="error" v-if="error" class="error" />
	
	<input class="textbox-input" type="text" v-bind:value="value" v-on:input="$emit('input', $event.target.value)" :placeholder="input.label" />

	<div class="textbox-suggestions" v-if="suggestions.length">
		<div class="textbox-suggestions-item" v-for="suggestion in suggestions" :key="suggestion.id" v-on:click="$emit('input', suggestion.name)">{{ suggestion.name }}</div>
	</div>

</div>

</template>

<script>

export default {

	props: ['input', 'value', 'error'],

	computed: {

		suggestions: function() {

			var suggestions = []

			if (this.input.suggestions && this.value.length) {

				suggestions = this.$_.filter(this.input.suggestions, function(suggestion) {

					return suggestion.name.toLowerCase().indexOf(this.value.toLowerCase()) === 0 && suggestion.name !== this.value

				}.bind(this))

			}

			return suggestions

		}

	}

}

</script>

<style scoped>

.textbox {
	margin-bottom: 20px;
}

.textbox-input {
	background-color: #f5f5f5;
	padding: 0px 20px;
	height: 46px;
	line-height: 46px;
	color: #1A234C;
	font-size: 14px;
	font-weight: 500;
	letter-spacing: 1.4px;
	border-radius: 23px;
	width: 100%;
}

.textbox-input::placeholder {
	color: #1A234C;
	font-size: 14px;
	font-weight: 500;
}

.textbox-suggestions {
	position: absolute;
	left: 0px;
	top: 47px;
	z-index: 1000;
	width: 100%;
	background-color: #1277D4;
	border-radius: 23px;
	padding: 10px 0px;
	overflow: hidden;
}

.textbox-suggestions-item {
	font-size: 14px;
	color: #fff;
	font-weight: 500;
	padding: 10px 20px;
	letter-spacing: 1.4px;
	cursor: pointer;
}

.textbox-suggestions-item:hover {
	background-color: rgba(0, 0, 0, 0.15);
}

</style>
